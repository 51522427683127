import { postRequest } from '@/libs/axios';


// 获取列表
export const getMealList = (params) => {
    return postRequest('/api/set/meal/list', params)
}
//添加编辑
export const addMeal = (params) => {
    return postRequest('/api/set/meal/add', params)
}
//删除
export const delMeal = (params) => {
    return postRequest('/api/set/meal/deleted', params)
}
export const checkMeal = (params) => {
    return postRequest('/api/set/meal', params)
}

export const updateStatus = (params) => {
    return postRequest('/api/set/meal/status/update', params)
}


