import { postRequest } from '@/libs/axios';


// 获取列表
export const getStoreList = (params) => {
    return postRequest('/api/store/list', params)
}
//添加编辑
export const addStore = (params) => {
    return postRequest('/api/store/add', params)
}
//详情
export const getDetial = (params) => {
    return postRequest('/api/store', params)
}
//删除
export const delStore = (params) => {
    return postRequest('/api/store/deleted', params)
}
//设置商家推荐人
export const setStoreParent = (params) => {
    return postRequest('/api/store/edit/store-parent', params)
}
//删除商家推荐人
export const delStoreParent = (params) => {
    return postRequest('/api/store/del/store-parent', params)
}

//绑定商家app用户
export const bindStoreUser = (params) => {
    return postRequest('/api/store/bind/laike/user', params)
}