<!-- 套餐管理页面 -->
<template>
  <div class="comboManagement">
    <div class="top-area clr">
      <div class="fl">
        <Button type="primary" style="margin-right:10px" @click="add">新增套餐</Button>
      </div>
      <Button class="fr" @click="$emit('back')" v-if="storeId">返回</Button>
      <!-- <Input style="width:240px" class="fr" search placeholder="请输入..." /> -->
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row }" slot="status">
          <i-switch
            v-model="row.status"
            true-value="1"
            false-value="0"
            :before-change="()=>{updateStatus(row)}"
          />
        </template>
        <template slot-scope="{ row }" slot="colonelSpreadRate">
          <div v-if="row.colonelSpreadRate">{{row.colonelSpreadRate}}%</div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <a style="margin-right: 5px" @click="edit(row)">修改</a>
          <a style="color:red;margin-right: 5px" @click="remove(row)">删除</a>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <Modal v-model="modelShow" :title="titleName" width="900">
      <addModel
        ref="addModel"
        v-if="modelShow"
        :storeId="storeId"
        :titleName="titleName"
        :currentData="currentData"
        :storeMsg="storeMsg"
      />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="save">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import addModel from './components/addModel.vue'
import { getMealList, addMeal, delMeal, updateStatus } from '@/api/comboManagement.js'


export default {
  components: {
    addModel
  },
  props: {
    storeMsg: {
      type: Object,
      default: () => { }
    },
    storeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tableHeight: window.innerHeight - 250,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '套餐id',
          key: 'id'
        },
        {
          title: '套餐名称',
          key: 'setMealTitle'
        },
        {
          title: '套餐总价',
          key: 'setMealPrice'
        },
        {
          title: '分润比例',
          slot: 'colonelSpreadRate',
        },
        {
          title: '上线日期',
          key: 'createTime'
        },
        {
          title: '状态',
          width: 80,
          slot: 'status',
        },
        {
          title: '操作',
          width: 160,
          slot: 'action',
        },
      ],
      dataList: [
        // {
        //   id: 1,
        //   name: '畅喝套餐',
        //   price: 1250,
        //   date: '2024-01-01'
        // },
        // {
        //   id: 2,
        //   name: '烧烤套餐',
        //   price: 850,
        //   date: '2024-01-01'
        // },
        // {
        //   id: 3,
        //   name: '果盘套餐',
        //   price: 1000,
        //   date: '2024-01-01'
        // },
        // {
        //   id: 4,
        //   name: '8人豪华套餐',
        //   price: 2250,
        //   date: '2024-01-01'
        // }
      ],
      modelShow: false,
      titleName: '新增',
      currentData: {},
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    findPageDataList() {
      let params = { size: this.size, pageIndex: this.current }
      if (this.storeId) {
        params.storeId = this.storeId
      }
      getMealList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    add() {
      this.currentData = {}
      this.titleName = '新增'
      this.modelShow = true
    },
    updateStatus(row) {
      let params = { id: row.id }
      let text = ''
      if (row.status == 0) {
        params.status = 1
        text = '上架'
      }
      if (row.status == 1) {
        params.status = 0
        text = '下架'
      }
      return new Promise((resolve) => {
        updateStatus(params).then(res => {
          if (res.success) {
            resolve()
            this.$Message.success(text + '成功！')
            this.findPageDataList()
          }
        })
      });

    },
    remove(row) {
      this.$Modal.confirm({
        title: "确认删除？",
        onOk: () => {
          delMeal({ id: row.id }).then(res => {
            if (res.success) {
              this.$Message.success('删除成功！')
              this.findPageDataList()
            }
          });
        },
      })
    },
    edit(row) {
      this.titleName = '修改'
      this.currentData = JSON.parse(JSON.stringify(row))
      this.modelShow = true
    },
    save() {
      let params = this.$refs.addModel.formData
      params.itemList = this.$refs.addModel.dataList
      this.$refs.addModel.$refs.formInline.validate((valid) => {
        if (valid) {
          addMeal(params).then(res => {
            if (res.success) {
              this.$Message.success('保存成功！')
              this.current = 1
              this.findPageDataList()
              this.modelShow = false
            }
          })
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
  }
}

</script>
<style lang='scss' scoped>
</style>