<template>
  <div class="addModel">
    <Form ref="formInline" :model="formData" :rules="ruleInline" :label-width="130">
      <FormItem label="套餐名称：" prop="setMealTitle">
        <Input v-model="formData.setMealTitle" style="width:600px"></Input>
      </FormItem>
      <FormItem label="套餐价格：" prop="setMealPrice">
        <Input type="number" v-model="formData.setMealPrice" style="width:600px"></Input>
      </FormItem>
      <FormItem label="商家：" v-if="!storeId">
        {{storeId}}
        <div class="ivu-input" style="width:600px" @click="modelShow=true">{{formData.storeName}}</div>
      </FormItem>
      <FormItem label="商家分润：" prop="storeSpreadRate">
        <Input type="number" v-model="formData.storeSpreadRate" style="width:400px">
          <span slot="append">%</span>
        </Input>
      </FormItem>
      <FormItem v-if="storeMsg.groupFlag" label="商家发团分润：" prop="noColonelStoreSpreadRate">
        <Input type="number" v-model="formData.noColonelStoreSpreadRate" style="width:400px">
          <span slot="append">%</span>
        </Input>
      </FormItem>
      <FormItem label="团长分润：" prop="colonelSpreadRate">
        <Input type="number" v-model="formData.colonelSpreadRate" style="width:400px">
          <span slot="append">%</span>
        </Input>
      </FormItem>
      <FormItem label="套餐内容：">
        <div class="clr">
          <Table class="fl" border :columns="columns" :data="dataList" width="600" height="300">
            <template slot-scope="{ row,index }" slot="product">
              <Input v-model="dataList[index].product"></Input>
            </template>
            <template slot-scope="{ row,index  }" slot="productNum">
              <Input type="number" v-model="dataList[index].productNum"></Input>
            </template>
            <template slot-scope="{ row,index  }" slot="productPrice">
              <Input type="number" v-model="dataList[index].productPrice"></Input>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <Button type="error" size="small" @click="remove(index)">删除</Button>
            </template>
          </Table>
          <Button class="fl" style="margin-left:10px" @click="add">新增</Button>
        </div>
      </FormItem>
    </Form>
    <Modal v-model="modelShow" title="选择商家" width="800">
      <businessSelect ref="businessSelect" v-if="modelShow" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="save">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>

import businessSelect from './businessSelect.vue'
import { checkMeal } from '@/api/comboManagement.js'

export default {
  components: {
    businessSelect
  },
  props: {
    storeMsg: {
      type: Object,
      default: () => { }
    },
    currentData: {
      type: Object,
      default: () => { }
    },
    titleName: {
      type: String,
      default: ''
    },
    storeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formData: {
        setMealTitle: '',
        setMealPrice: '',
        colonelSpreadRate: '',
        storeSpreadRate: '',
        noColonelStoreSpreadRate: '',
        storeId: '',
        itemList: [],
        storeName: ''
      },
      columns: [
        {
          title: '商品名称',
          key: 'product',
          width: 180,
          slot: 'product'
        },
        {
          title: '产品数量',
          key: 'productNum',
          slot: 'productNum'
        },
        {
          title: '价格',
          key: 'productPrice',
          slot: 'productPrice'
        },
        {
          title: '操作',
          slot: 'action'
        },
      ],
      ruleInline: {
        setMealTitle: [
          { required: true, message: '请填写套餐名称！', trigger: 'blur' }
        ],
        setMealPrice: [
          { required: true, message: '请填写套餐价格！', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const rate = parseFloat(value); // 转换为浮点数
              if (isNaN(rate)) {
                callback(new Error('请输入有效的数字！'));
              }else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        colonelSpreadRate: [
          { required: true, message: '请填写团长分润比例！', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const rate = parseFloat(value); // 转换为浮点数
              if (isNaN(rate)) {
                callback(new Error('请输入有效的数字！'));
              } else if (rate <= 0 || rate > 100) {
                callback(new Error('团长分润比例必须大于0且不超过100！'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        storeSpreadRate: [
          { required: true, message: '请填写商家分润比例！', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const rate = parseFloat(value); // 转换为浮点数
              if (isNaN(rate)) {
                callback(new Error('请输入有效的数字！'));
              } else if (rate <= 0 || rate > 100) {
                callback(new Error('商家分润比例必须大于0且不超过100！'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        noColonelStoreSpreadRate: [
          { required: true, message: '请填写商家发团分润比例！', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const rate = parseFloat(value); // 转换为浮点数
              if (isNaN(rate)) {
                callback(new Error('请输入有效的数字！'));
              } else if (rate <= 0 || rate > 100) {
                callback(new Error('商家发团分润比例必须大于0且不超过100！'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
      },
      dataList: [
        // {
        //   product: '爆米花',
        //   productNum: "2",
        //   unit: '包',
        //   productPrice: '50'
        // },
        // {
        //   product: '啤酒',
        //   productNum: "20",
        //   unit: '瓶',
        //   productPrice: '500'
        // },
        // {
        //   product: '水果拼盘',
        //   productNum: "2",
        //   unit: '盘',
        //   productPrice: '150'
        // }
      ],
      modelShow: false
    }
  },
  created() {
    if (this.titleName != '新增') {
      // this.formData = this.currentData
      checkMeal({ id: this.currentData.id }).then(res => {
        if (res.success) {
          this.formData = res.result
          this.formData.setMealPrice = String(res.result.setMealPrice)
          this.dataList = res.result.itemList
        }
      })
    }
    if (this.storeId) {
      this.formData.storeId = this.storeId
    }
  },
  methods: {
    add() {
      // this.formData.itemList.push({
      //   product: "",
      //   productNum: "",
      //   productPrice: "",
      // })
      this.dataList.push({
        product: "",
        productNum: "",
        productPrice: "",
      });
    },
    remove(index) {
      this.dataList.splice(index, 1);
    },
    save() {
      if (this.$refs.businessSelect.currentData.id) {
        this.formData.storeId = this.$refs.businessSelect.currentData.id
        this.formData.storeName = this.$refs.businessSelect.currentData.storeName
        this.modelShow = false
      } else {
        this.$Message.warning('请选择商家！')
      }
    }
  }
}
</script>

<style lang='scss' scoped>
</style>