<template>
  <div class="businessSelect">
    <div class="table-area">
      <Table
        :columns="columns"
        highlight-row
        @on-current-change="onCurrentChange"
        :data="dataList"
        size="small"
        :height="tableHeight"
      >
        <template slot-scope="{ row }" slot="doorPic">
          <img style="width:auto;height:40px;" :src="row.doorPic" alt />
        </template>
        <template slot-scope="{ row }" slot="storeType">{{getStoreTypeName(row.storeType)}}</template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        :page-size="size"
        show-total
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getStoreList } from '@/api/businessManagement.js'
export default {
  data() {
    return {
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: 'id',
          key: 'id'
        },
        {
          title: '商家名称',
          key: 'storeName'
        },
        {
          title: '商户类型',
          key: 'storeType',
          slot: 'storeType',
        },
        {
          title: '商户地址',
          key: 'storeAddress'
        },
        {
          title: '商户门头照',
          key: 'doorPic',
          slot: 'doorPic'
        },
        {
          title: '商户电话',
          key: 'storeTel'
        },
      ],
      dataList: [],
      currentData: {},
      tableHeight: 300
    }
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    findPageDataList() {
      getStoreList({ size: this.size, pageIndex: this.current }).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    onCurrentChange(row) {
      this.currentData = row
    },
    getStoreTypeName(code) {
      if (code) {
        let name = ''
        this.dictionaryEntry.forEach(item => {
          if (item.dicCode == code) {
            name = item.dicName
          }
        })
        return name
      } else {
        return ''
      }
    },
  }
}
</script>

<style>
</style>